import * as React from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ApplicationState } from "../../store";
import { FaTimes, FaClock, FaCheck, FaFileAlt, FaShare, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import Swal from 'sweetalert2'
import path from 'path';

import * as SearchWorkflowDashboardStore from '../../store/workflow/SearchWorkflowDashboard'
import * as ModelSearchWorkflowDashboard from '../../models/workflow/SearchWorkflowInbox'

import * as ApproveRejectWorkflowInformationStore from '../../store/workflow/ApproveRejectWorkflowInformation';
import * as ModelApproveRejectWorkflowInformation from '../../models/workflow/ApproveRejectWorkflowInformation';

import { parseJwt, onClickFileShow } from '../../modules/Common'
import { getCurrentTimestamp } from "helpers/utils";

//import ReactDOM from 'react-dom'

type Store = ModelSearchWorkflowDashboard.GetWorkflowInboxValueState
    & typeof SearchWorkflowDashboardStore.actionCreators

type Props = {
    [key: string]: any;
} & {
    presentMode: 'information' | 'document' | 'classic';
};

class WorkflowDashboardInformation extends React.Component<Props, {
    hideComponent: boolean,
    iFrameHeight: any,
    src: any,
    isLoading: boolean,
    checkwfid: boolean,
    formData: Array<ModelSearchWorkflowDashboard.ResponseWorkflowInboxValue>,
    checkresponse: boolean,
    checkapprove: boolean,
    checkreject: boolean,
    messageModal: boolean,
    modeApproveReject: string,
    message: string,
    docNoList: any,
    successModal: boolean,
    successModalHeader: string,
    wfId: string,
    docNo: string,
    wfInstanceNo: string,
    userId: string,
    token: string,
    sid: string,
    appId: string,
    wfType: string
}, Store> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            checkwfid: true,
            checkresponse: true,
            formData: [],
            checkapprove: true,
            checkreject: true,
            src: '',
            iFrameHeight: '',
            hideComponent: false,
            messageModal: false,
            modeApproveReject: "",
            message: "",
            docNoList: [],
            successModal: false,
            successModalHeader: "",
            wfId: "",
            docNo: "",
            wfInstanceNo: "",
            userId: "",
            token: "",
            sid: '',
            appId: '',
            wfType: ''
        };
    }

    componentDidMount() {
        var { appId, wfId, docNo, wfInstanceNo, userId, token, showmodal, statusResponse, canReject, isOnlineForm, presentMode } = this.props
        console.log(this.props)

        var src =
            window.location.protocol + '//' + window.location.host
            + '/workflow-report-detail?app_id=' + encodeURIComponent(appId)
            + '&userId=' + encodeURIComponent(userId)
            + '&docNo=' + encodeURIComponent(docNo)
            + '&wfId=' + encodeURIComponent(wfId)
            + '&wfInstanceNo=' + encodeURIComponent(wfInstanceNo)
            + '&token=' + encodeURIComponent(token)
            + '&presentMode=' + encodeURIComponent(presentMode)
            + '&timestamp=' + getCurrentTimestamp()

        if (canReject == true) {
            src += '&can_reject=true'
        }

        console.log(src)

        //var access_token = parseJwt(token);
        //if (!appId) {
        //    appId = access_token.app
        //}

        //var extra = JSON.parse(access_token.extra)
        //var user_id = userId
        //if (user_id) {
        //    if (extra.citizen_id) {
        //        console.log('Azure Login ID: ' + extra.citizen_id)
        //        user_id = extra.citizen_id
        //    } else if (extra.username) {
        //        console.log('Local Login ID: ' + extra.sid)
        //        user_id = extra.username
        //    }
        //}

        this.setState({
            src: src,
            docNo: docNo,
            wfInstanceNo: wfInstanceNo,
            userId: userId,
            token: token,
            sid: appId,
            appId: appId
        })

        window.addEventListener("message", (event) => {
            if (event.data != undefined) {
                if (event.data.hasOwnProperty('key')) {
                    const obj = JSON.parse(event.data)
                    switch (obj.key) {
                        case 'height':
                            var IFrame = document.getElementById('IFrame') as HTMLIFrameElement
                            IFrame.height = obj.value
                            console.log(obj.value)

                            break;
                        case 'wf_type':
                            this.setState({
                                wfType: obj.value
                            })
                            console.log(obj.value)

                            break;
                        case 'loading_show':
                            this.showLoading()

                            // code block
                            break;
                        case 'loading_hide':
                            this.hideLoading()

                            // code block
                            break;
                        case 'message_modal_show':
                            this.setState({
                                messageModal: true,
                                modeApproveReject: obj.value
                            })

                            // code block
                            break;
                        case 'success_modal_show':

                            // code block
                            break;
                        default:
                        // code block
                    }
                }

            }
        }, false);

        this.props.requestsearchworkflowDashboard(true, "POST", wfId, docNo, userId, wfInstanceNo, token, appId)

    }

    showLoading() {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.setState({
            isLoading: true
        })
    }

    hideLoading() {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        this.setState({
            isLoading: false
        })
    }

    componentDidUpdate() {
        const { statusWorkflowInboxValue, responseWorkflowInboxValue, isLoadApproveReject, isLoadWorkflowInboxValue, messageResponseApproveReject, statusResponseApproveReject } = this.props
        const { wfId, docNo, wfInstanceNo, userId, token, appId } = this.state
        if (statusWorkflowInboxValue == 200 && responseWorkflowInboxValue != undefined && this.state.checkresponse) {
            this.props.jsonReportCallback(responseWorkflowInboxValue['jsonReport'])
            this.setState({
                checkresponse: false
            })
        }

        if (isLoadApproveReject && statusResponseApproveReject == 200 && (this.state.checkapprove || this.state.checkreject)) {
            console.log('WFINFO APPROVE SUCCESS')
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.hideLoading()
            this.setState({
                checkresponse: true,
                checkapprove: false,
                checkreject: false,
                formData: [],
                messageModal: false,
                modeApproveReject: "",
                message: "",
                successModal: true
            })
            //setTimeout(() => {
            //    Swal.fire({
            //        title: 'สำเร็จ!',
            //        text: 'กดปุ่มยืนยัน',
            //        icon: 'success',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ยืนยัน!',
            //        allowOutsideClick: false,
            //    }).then((result) => {
            //        if (result.value) {
            //            if (this.state.checkurl == "No_URL") {
            //                this.props.blindopenModal("reload")
            //            } else {
            //                this.setState({
            //                    controlRedirect: true
            //                })
            //            }
            //            this.setState({
            //                checkapprove: true,
            //            })
            //            this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)

            //            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            //        }
            //    })
            //}, 500);
        } else if (isLoadApproveReject && statusResponseApproveReject == 400 && (this.state.checkapprove || this.state.checkreject)) {
            try {
                var msg = JSON.parse(messageResponseApproveReject)
                //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.hideLoading()
                this.setState({
                    checkresponse: true,
                    checkapprove: false,
                    checkreject: false,
                    formData: [],
                    messageModal: false,
                    modeApproveReject: "",
                    message: ""
                })

                this.props.requestsearchworkflowDashboard(true, "CLEAR", wfId, docNo, userId, wfInstanceNo)
                this.props.requestApproveWorkflowInformation(true, "CLEAR", "", "", "");
                this.props.requestsearchworkflowDashboard(true, "POST", wfId, docNo, userId, wfInstanceNo, token, appId)
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        text: msg.message,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            this.setState({
                                checkapprove: true,
                            })
                            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        }
                    })
                }, 500);
            } catch {
                this.hideLoading()
                this.setState({
                    checkresponse: true,
                    checkapprove: false,
                    checkreject: false,
                    formData: [],
                    messageModal: false,
                    modeApproveReject: "",
                    message: ""
                })

                this.props.requestsearchworkflowDashboard(true, "CLEAR", wfId, docNo, userId, wfInstanceNo)
                this.props.requestApproveWorkflowInformation(true, "CLEAR", "", "", "");
                this.props.requestsearchworkflowDashboard(true, "POST", wfId, docNo, userId, wfInstanceNo, token, appId)
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        html: messageResponseApproveReject,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            this.setState({
                                checkapprove: true,
                            })
                            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        }
                    })
                }, 500);
            }
        }
    }

    handleReject = (wfInstanceNo: string, docNo: string, userId: string, wfType: string, sid: string) => {
        console.log(wfInstanceNo + " | " + docNo + " | " + userId + " | " + wfType)
        var wfUse: any = []
        var wfins = {
            app_id: sid,
            user_id: userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        }
        wfUse.push(wfins)
        this.setState({
            docNoList: wfUse,
            successModalHeader: "Rejected Status",
            messageModal: true,
            modeApproveReject: "reject"
        })
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        // this.showLoading()
        // setTimeout(() => {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        // this.hideLoading()
        // this.setState({
        //     messageModal: true,
        //     modeApproveReject: "reject"
        // })
        //this.showMessageModal()
        // }, 500)
        //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    hideSpinner = () => {
        this.setState({
            isLoading: false
        })
    }

    handleMessage = () => {
        if (this.state.messageModal) {
            this.setState({
                messageModal: false,
                modeApproveReject: "",
                message: "",
                docNoList: []
            })
        } else {
            this.setState({
                messageModal: true
            })
        }

        //var IFrame = document.getElementById('IFrame') as HTMLIFrameElement
        //IFrame.contentWindow.postMessage(
        //    JSON.stringify({
        //        key: 'handle_message',
        //        value: this.state.messageModal
        //    }),
        //    '*'
        //);
    }

    onchangeMessage = (e: { target: { name: any; value: any; }; }) => {
        this.setState({
            message: e.target.value
        })
        setTimeout(() => {
            console.log(this.state.message)
        }, 500);
    }

    onsuccessApproveReject = () => {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.showLoading()

        var messagePush: any = []
        messagePush = this.state.docNoList
        for (let i = 0; i < messagePush.length; i++) {
            messagePush[i]['app_id'] = this.state.appId
            messagePush[i]['user_id'] = this.state.userId
            messagePush[i]['wf_type'] = this.state.wfType
            messagePush[i]['message'] = this.state.message
            messagePush[i]['instance_no_list'] = [this.state.wfInstanceNo]
        }
        this.setState({
            docNoList: messagePush
        })
        console.log(messagePush);
        console.log(this.state.docNoList);
        console.log(this.state);

        if (this.state.modeApproveReject == "approved") {
            //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestApproveWorkflowList(true, "PUT", this.state.docNoList);
        } else {
            //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestRejectWorkflowList(true, "PUT", this.state.docNoList);
        }

        //var IFrame = document.getElementById('IFrame') as HTMLIFrameElement
        //IFrame.contentWindow.postMessage(
        //    JSON.stringify({
        //        key: 'on_success_approve_reject',
        //        value: this.state.message
        //    }),
        //    '*'
        //);
    }

    handleSuccessModal = () => {
        const { wfId, docNo, userId, wfInstanceNo } = this.state
        if (this.state.successModal) {
            this.props.requestApproveWorkflowInformation(true, "CLEAR", userId, docNo, "");
            this.props.requestsearchworkflowDashboard(true, "CLEAR", wfId, docNo, userId, wfInstanceNo)
            //this.props.requestsearchworkflowDashboard(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo, workflowValueUse.token, workflowValueUse.appId)
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //this.props.refreshPageChild()
            this.setState({
                successModal: false,
                checkapprove: true,
                successModalHeader: ""
            })
        } else {
            this.setState({
                successModal: true,
                successModalHeader: ""
            })
        }
    }

    render() {
        const { wfId, wfType, docNo, wfInstanceNo, userId, appId, canReject, statusWorkflowInboxValue, responseWorkflowInboxValue, showmodal, isLoadWorkflowInboxValue } = this.props

        if (this.state.isLoading) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
        else {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }

        return (
            <Container fluid={true} className="mt-3">
                {canReject == true &&
                    <Row>
                        <Col>
                            {/*<Button variant="outline-success mx-1" size="sm" onClick={() => this.handleApprove(workflowValueUse.wfInstanceNo, workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'], workflowValueUse.appId ? workflowValueUse.appId : '')}>*/}
                            {/*    <FaCheck />&nbsp;Approve*/}
                            {/*</Button>*/}
                            <Button variant="outline-danger mx-1" size="sm" onClick={() => this.handleReject(wfInstanceNo, docNo, userId, wfType, appId ? appId : '')}>
                                <FaTimesCircle />&nbsp;Reject
                            </Button>
                        </Col>
                    </Row>
                }
                <Row className="mt-3 h-[calc(100vh_-_10vh)]">
                    <Col>
                        <iframe
                            src={this.state.src}
                            width="100%"
                            height="100%"
                            onLoad={this.hideSpinner}
                            frameBorder="0"
                        />
                    </Col>
                </Row>

                <Modal isOpen={this.state.messageModal} toggle={() => this.handleMessage()} size="lg" backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => this.handleMessage()}>Message</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={5} onChange={this.onchangeMessage} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/*  <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button>*/}
                        <Button variant="info" onClick={() => this.onsuccessApproveReject()}>Confirm</Button>
                        <Button variant="secondary" onClick={() => this.handleMessage()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {/*success modal*/}
                <Modal isOpen={this.state.successModal} toggle={() => this.handleSuccessModal()} size="lg" backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => this.handleSuccessModal()}>{this.state.successModalHeader}</ModalHeader>
                    <ModalBody scrollable>
                        <Row>
                            <Col>
                                {this.props.isLoadApproveReject && this.props.statusResponseApproveReject == 200 && this.props.responseApproveReject.length != 0 && this.props.responseApproveReject['result_list'].map((item: ModelApproveRejectWorkflowInformation.ApproveRejectWorkflowInformationResponse, idx) => {
                                    console.log(item)
                                    return (
                                        <Card className="shadow-sm p-1 mb-1 bg-white rounded">
                                            <CardBody>
                                                <Row>
                                                    <Col lg={2}>
                                                        <div style={{ alignContent: 'center', verticalAlign: 'middle', textAlign: 'center' }}>
                                                            {item.status == "SUCCESS" ? <React.Fragment><FaCheckCircle size={50} color="green" /></React.Fragment> : <React.Fragment><FaTimesCircle size={80} color="red" style={{ paddingTop: '30px' }} /></React.Fragment>}
                                                        </div>
                                                    </Col>
                                                    <Col lg={10}>
                                                        <Table className="table table-borderless table-hover" size="sm">
                                                            <tr className="require-field" style={{ width: '100%' }}>
                                                                <td className="font-weight-bold" style={{ width: '30%' }}>Document NO. :</td>
                                                                <td style={{ width: '70%' }}>{item.docNo}</td>
                                                            </tr>
                                                            <tr className="require-field" style={{ width: '100%' }}>
                                                                <td className="font-weight-bold" style={{ width: '30%' }}>Status :</td>
                                                                <td style={{ width: '70%' }}>{item.status}</td>
                                                            </tr>
                                                            <tr className="require-field" style={{ width: '100%' }}>
                                                                <td className="font-weight-bold" style={{ width: '30%' }}>Message :</td>
                                                                <td style={{ width: '70%' }}>{item.message}</td>
                                                            </tr>
                                                            {item.status != 'SUCCESS' ?
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Error Message :</td>
                                                                    <td style={{ width: '70%' }}>{item.errorMessage}</td>
                                                                </tr>
                                                                : ''}
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="danger" onClick={() => this.handleSuccessModal()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.searchworkflowDashboard,
    ...state.approveRejectWorkflowInformation
}), ({
    ...SearchWorkflowDashboardStore.actionCreators,
    ...ApproveRejectWorkflowInformationStore.actionCreators
}))(WorkflowDashboardInformation)